import {handleResponse} from '../helpers';
import {apiUrl}         from "../utils/ConnectionStrings";
import queryString      from "../utils/queryString";

export const roomService = {
    getAll,
    create,
    update,
    remove,
    getById,
    getByOrganisationId,
    uploadPhotos,
    getAllRooms,
    getByPage,
    getPdfByRentalObject,
    getRoomsByIds
};

function create(floorId, roomModel) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
        },
        body: roomModel
    };

    return fetch(apiUrl + "rental-objects/" + floorId, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function update(id, roomModel) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
        },
        body: roomModel
    };
    return fetch(apiUrl + "rental-objects/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getAll(floorId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "rental-objects/get-all-by-floor/" + floorId, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}
function getAllRooms() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(apiUrl + "rental-objects", requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}
function getByPage(params) {
    const requestOptions = {
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json"
        }
    };
    return fetch(`${apiUrl}rental-objects/page?${params}`, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}


function remove(roomId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };

    return fetch(apiUrl + "rental-objects/" + roomId, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function uploadPhotos(id, files) {
    const formData = new FormData();
    Array.from(files).forEach(_ => formData.append('body', _));
    const uploadRequestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Accept': 'application/json'
        },
        body: formData
    };
    return fetch(apiUrl + 'rental-objects/upload-images/' + id, uploadRequestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "rental-objects/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getByOrganisationId(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "rental-objects/get-by-organisation-id/" + id, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}

function getPdfByRentalObject(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    };
    return fetch(apiUrl + "rental-objects/generate-pdf/" + id, requestOptions)
        .then(_ => {
            return _.blob();
        });
}

function getRoomsByIds(ids) {
    const idsString = queryString({roomId: ids})
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
    };
    return fetch(`${apiUrl}rental-objects/get-by-ids?${idsString}`, requestOptions)
        .then(handleResponse)
        .then(_ => {
            return _;
        });
}