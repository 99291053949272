import React, { useCallback, useState } from 'react'
import { MDBBtn } from 'mdbreact'
import { Formik, useField } from 'formik'
import { object as objectYup, string as stringYup } from 'yup'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'

import InputItem from '../items/input-item'
import { isworkService } from '../../services/isworkService'

export const validationSchema = objectYup().shape({
    inn: stringYup()
        .required("Поле обязательное для заполнения")
        .test('validate-inn', 'Поле заполнено не корректно', (str = '') => [10, 12].includes(str.length)),
    shortName: stringYup().required("Поле обязательное для заполнения"),
    fullName: stringYup().required("Поле обязательное для заполнения"),
    phoneHead:  stringYup().required("Поле обязательное для заполнения"),
    email: stringYup().required("Поле обязательное для заполнения"),
});


const CompanyForm = ({values = {}, onSubmit}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [changed, setChanged] = useState(false);
    const [initial, setInitial] = useState(initialData);
    const history = useHistory();

    const getCompanyData = useCallback(async(inn, setValues, values) => {
        try{
            const data = await isworkService.fetchCompanyDataByInn(inn);
            setInitial(prev => ({...prev, kindActivity: data.kindActivity}))
            if(data){
                setValues({...values, ...data});
            }

        }catch (err) {
            console.error(err);
            enqueueSnackbar("Не удалось получить данные по ИНН");
        }
    }, [enqueueSnackbar]);

    return (
        <Formik
            initialValues={{...initialData, ...values}}
            onSubmit={values => onSubmit({...initial, ...values})}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({handleSubmit, isValid, isSubmitting, errors, values, setValues, ...props}) => (
                <form className="owners-form" onChange={() => setChanged(true)} onSubmit={(e) => {
                    handleSubmit(e);
                    setChanged(false);
                }}>
                    <div className="owners-form__basic-info owners-form__item">
                        <div className="owners-form__item-title">
                            ОБЩАЯ ИНФОРМАЦИЯ О КОМПАНИИ
                        </div>
                        <div className="owners-form__item-inner">
                            <div className="owners-form__item-row">
                                <FormikField name="inn" label="ИНН" id="inn" icon="building" type="number"
                                             required/>
                            </div>
                            <div>
                                <FormikField name="kpp" label="КПП" id="kpp" icon="building" type="number"/>

                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="shortName" label="НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ" id="name" icon="building" required/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="fullName" label="ПОЛНОЕ НАИМЕНОВАНИЕ ОРГАНИЗАЦИИ" id="fullname" icon="building"
                                             required/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="certificateOgrn" label="ОГРН" id="ogrn" icon="building"/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="okved" label="ОКВЭД (основной вид деятельности)" id="okved" icon="building"/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="legalAddress" label="ЮРИДИЧЕСКИЙ АДРЕС" id="legal-adress" icon="building"/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="mailingAddress" label="ПОЧТОВЫЙ АДРЕС" id="post-adress" icon="building"/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="head" label="РУКОВОДИТЕЛЬ ОРГАНИЗАЦИИ" id="head" icon="building"/>
                            </div>
                        </div>
                    </div>
                    <div className="owners-form__requisites owners-form__item">
                        <div className="owners-form__item-title">
                            БАНКОВСКИЕ РЕКВИЗИТЫ
                        </div>
                        <div className="owners-form__item-inner">
                            <div className="owners-form__item-row">
                                <FormikField name="bik" label="БИК" id="bik" icon="building" type="number"/>
                                <FormikField name="bank-inn" label="ИНН" id="bank-inn" icon="building" type="number"/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="bank" label="НАИМЕНОВАНИЕ БАНКА" id="bank-name" icon="building"/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="correspondentAccount" label="КОРРЕСПОНДЕНТСКИЙ СЧЕТ" id="cor-score" icon="building"
                                             type="number"/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="checkingAccount" label="РАССЧЕТНЫЙ СЧЕТ ОРГАНИЗАЦИИ" id="score" icon="building"
                                             type="number"/>
                            </div>
                        </div>
                    </div>
                    <div className="owners-form__contacts owners-form__item">
                        <div className="owners-form__item-title">
                            КОНТАКТЫ
                        </div>
                        <div className="owners-form__item-inner">
                            <div className="owners-form__item-row"> <FormikField name="head" label="ФИО ответственного лица" id="response-fio" icon="building" required/></div>
                            <div className="owners-form__item-row">
                                <FormikField name="phoneHead" label="Телефон" id="tel" icon="building" type="tel" required/>
                                <FormikField name="email" label="E-mail" id="mail" icon="building" type="email" required/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="chiefAccountant" label="Главный бухгалтер" id="bui" icon="building"/>
                            </div>
                            <div className="owners-form__item-row">
                                <FormikField name="phoneChiefAccountant" label="Телефон" id="b-tel" icon="building" type="tel"/>
                                <FormikField name="b-mail" label="E-mail" id="b-mail" icon="building" type="email"/>
                            </div>
                        </div>
                    </div>
                    <div className="owners-form__notes owners-form__item" >
                        <div className="owners-form__item-title">
                            ПРИМЕЧАНИЕ
                        </div>
                        <div className="owners-form__item-inner">
                            <textarea/>
                        </div>
                    </div>
                    <div className="owners-form__controls">
                        <div>
                            <MDBBtn
                                outline
                                disabled={!!errors.inn}
                                type="button"
                                onClick={() => {
                                    if(values.inn){
                                        getCompanyData(values.inn, setValues, values);
                                    }
                                }}
                            >Заполнить поля по ИНН</MDBBtn>
                        </div>
                        <div>
                            <MDBBtn outline color="blue-grey" type="button" onClick={history.goBack}>Назад</MDBBtn>
                            <MDBBtn
                                outline
                                color="success"
                                disabled={!isValid || isSubmitting || !changed}
                                type="submit"
                            >Сохранить</MDBBtn>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default CompanyForm

const FormikField = ({ label, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <div className="owners-form__item-field">
            <InputItem {...field} {...props} error={meta.touched ? meta.error : undefined} label={label}/>
        </div>
    )
}

const initialData = {
    inn: '',
    kpp: '',
    shortName: '',
    fullName: '',
    certificateOgrn: '',
    okved: '',
    legalAddress: '',
    mailingAddress: '',
    head: '',
    phoneHead: '',
    bik: '',
    bank: '',
    email: '',
    correspondentAccount: '',
    checkingAccount: '',
    chiefAccountant: '',
    phoneChiefAccountant: '',
    bui: '',
    'b-mail': ''
}